// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HeaderSection from './components/HeaderSection'; // New import
import HeroSection from './components/HeroSection';
import IntroductionSection from './components/IntroductionSection';
import FeaturesSection from './components/FeaturesSection';
import HowItWorksSection from './components/HowItWorksSection';
import UseCasesSection from './components/UseCasesSection';
import TestimonialsSection from './components/TestimonialsSection';
import CTASection from './components/CTASection';
import Footer from './components/Footer';
import VideoSection from './components/VideoSection'; // New component


function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />
            <IntroductionSection />
            <FeaturesSection />
            <HowItWorksSection />
            <UseCasesSection />
            <CTASection />
            <Footer />
          </>
        }/>
        <Route path="/introduction" element={<VideoSection />} /> {/* New Video Route */}
      </Routes>
    </div>
  </Router>



  );
}

export default App;


