import React from 'react';

function VideoPage() {
  return (
    <div style={{ height: "100vh", width: "100vw", margin: "0", padding: "0" }}>
      <video style={{ height: "100%", width: "100%" }} controls autoPlay>
        <source src="./introduction.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoPage;
