import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import zlatanImage from '../assets/images/zalatan-image.jpg';
import potinImage from '../assets/images/potin-image.png';
import johnImage from '../assets/images/john-image.png';
import satoshiImage from '../assets/images/satoshi-image.png';

import jokerImage from '../assets/images/joker-image.png';
import peterImage from '../assets/images/peter-image.png';
import batmanImage from '../assets/images/batman-image.jpg';


const TestimonialsContainer = styled.section`
  padding: 5rem 2%;
  background-color: #f0f0f0;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
  font-weight: 700;
`;

const SliderWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const TestimonialCard = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
  margin: 0 10px;
  height: 400px;  /* Set a fixed height */
  overflow: hidden;

  &.slick-center {
    transform: scale(1.1); /* Slightly enlarge the center card */
    z-index: 2; /* Ensure it appears above other cards */
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  }
`;


const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  object-fit: cover;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-style: italic;
`;

const AuthorName = styled.h4`
  font-size: 1.3rem;
  color: #141e30;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const AuthorTitle = styled.p`
  font-size: 1rem;
  color: #999;
`;

const TestimonialsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    centerMode: true,       // Enables centering the active slide
    centerPadding: "0px",   // Ensures the active slide is fully centered
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "40px",
        },
      },
    ],
  };

  return (
    <TestimonialsContainer>
      <Title>What Legends Will Say about Identium After Connecting.</Title>
      <SliderWrapper>
        <Slider {...settings}>
          <TestimonialCard>
            <Avatar src={johnImage} alt="John Wick" />
            <TestimonialText>
              "It keeps my online presence secure and untouchable. No one dares to challenge my identity."
            </TestimonialText>
            <AuthorName>John Wick</AuthorName>
            <AuthorTitle>Master Assassin</AuthorTitle>
          </TestimonialCard>

          <TestimonialCard>
            <Avatar src={potinImage} alt="Vladimir Putin" />
            <TestimonialText>
              "The security is unparalleled, even my top agents can't bypass it. But that’s fine—I prefer using it for my own digital identity protection."
            </TestimonialText>
            <AuthorName>Vladimir Putin</AuthorName>
            <AuthorTitle>President</AuthorTitle>
          </TestimonialCard>

          <TestimonialCard>
            <Avatar src={zlatanImage} alt="Zlatan Ibrahimović" />
            <TestimonialText>
              "This platform protects Zlatan's identity. And that’s all you need to know."
            </TestimonialText>
            <AuthorName>Zlatan Ibrahimović</AuthorName>
            <AuthorTitle>Football Legend</AuthorTitle>
          </TestimonialCard>

          <TestimonialCard>
            <Avatar src={jokerImage} alt="Joker" />
            <TestimonialText>
            "Why so serious? My digital twin keeps me laughing… all the way to the bank!"
            </TestimonialText>
            <AuthorName>Joker</AuthorName>
            <AuthorTitle>Agent of Chaos</AuthorTitle>
         </TestimonialCard>

         <TestimonialCard>
            <Avatar src={satoshiImage} alt="Satoshi Nakamoto" />
            <TestimonialText>
              "As the creator of Bitcoin, I appreciate the decentralized and secure nature of this platform. That's all I need."
            </TestimonialText>
            <AuthorName>Satoshi Nakamoto</AuthorName>
            <AuthorTitle>Bitcoin Creator</AuthorTitle>
          </TestimonialCard>

        <TestimonialCard>
            <Avatar src={peterImage} alt="Peter Parker (Spider-Man)" />
            <TestimonialText>
            "With great power comes great responsibility... and Identium ensures my identity stays under wraps."
            </TestimonialText>
            <AuthorName>Peter Parker</AuthorName>
            <AuthorTitle>Friendly Neighborhood Spider-Man</AuthorTitle>
        </TestimonialCard>

        <TestimonialCard>
            <Avatar src={batmanImage} alt="Bruce Wayne (Batman)" />
            <TestimonialText>
            "With Identium, my digital identity stays as secure as the Batcave. Not even the Joker can break this code!"
            </TestimonialText>
            <AuthorName>Bruce Wayne</AuthorName>
            <AuthorTitle>Batman</AuthorTitle>
        </TestimonialCard>
        </Slider>
      </SliderWrapper>
    </TestimonialsContainer>
  );
};

export default TestimonialsSection;
