// src/components/CTASection.js

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const CTAContainer = styled.section`
  padding: 5rem 10%;
  background: linear-gradient(45deg, #243b55, #141e30);
  text-align: center;
  color: #ffffff;
  position: relative;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled(motion.a)`
  display: inline-block;
  padding: 1rem 2.5rem;
  background: #29abe2;
  color: #ffffff;
  border-radius: 50px;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  margin-right: 1rem;
  
  &:hover {
    background: #22a1d3;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
  }
`;

const SecondaryLink = styled.a`
  display: inline-block;
  padding: 1rem 2.5rem;
  color: #ffffff;
  font-size: 1.2rem;
  text-decoration: underline;
  margin-left: 1rem;
  cursor: pointer;
  
  &:hover {
    color: #22a1d3;
  }
`;

const CTASection = () => {
  return (
    <CTAContainer id="cta">
      <Title>Ready to Secure and Empower Your Digital Identity?</Title>
      <CTAButton
        href="https://app.identium.io"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Join Now
      </CTAButton>
      <SecondaryLink href="#learn-more">Learn More</SecondaryLink>
    </CTAContainer>
  );
};

export default CTASection;
