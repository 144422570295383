import React from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

const HeaderContainer = styled.header`
  width: 100%;
  background: linear-gradient(45deg, #141e30, #243b55);
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 1rem 0;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Logo = styled.a`
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    color: #29abe2;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  gap: 2rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #141e30, #243b55);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
`;

const NavLink = styled.li`
  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease;

    &:hover {
      color: #29abe2;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffffff;
  }
`;

const CloseIcon = styled(FaTimes)`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const OpenIcon = styled(FaBars)`
  display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
`;

const HeaderSection = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <Nav>
        <Logo href="#hero">Identium</Logo>
        <NavLinks isOpen={isOpen}>
          <NavLink><a href="#introduction">Introduction</a></NavLink>
          <NavLink><a href="#features">Features</a></NavLink>
          <NavLink><a href="#how-it-works">How It Works</a></NavLink>
          <NavLink><a href="#use-cases">Use Cases</a></NavLink>
        </NavLinks>
        <MenuIcon onClick={toggleMenu}>
          <CloseIcon isOpen={isOpen} />
          <OpenIcon isOpen={isOpen} />
        </MenuIcon>
      </Nav>
    </HeaderContainer>
  );
};

export default HeaderSection;
