import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const IntroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 10%;
  background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
  color: #333;

  @media (max-width: 768px) {
    padding: 4rem 5%;
  }
`;

const TextContent = styled.div`
  max-width: 800px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #333;
`;

const Description = styled.p`
  font-size: 1.6rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  color: #555;
`;

const CTAButton = styled(motion.a)`
  padding: 1rem 2.5rem;
  background-color: #29abe2;
  color: #ffffff;
  border-radius: 50px;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #22a1d3;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
  }
`;

const IntroductionSection = () => {
  return (
    <IntroContainer>
      <TextContent
        as={motion.div}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Title>Discover Unmatched Data Privacy and Take Control of Your Data</Title>
        <Description>
            Transform digital identity concept by integrating AI and blockchain, delivering a secure, personalized experience that adapts to your needs.        
        </Description>
        <CTAButton
          href="https://app.identium.io"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get Started
        </CTAButton>
      </TextContent>
    </IntroContainer>
  );
};

export default IntroductionSection;
