import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaVrCardboard, FaHeartbeat, FaMoneyBillWave, FaGamepad, FaRoad , FaBuilding, FaShieldAlt, FaComments, FaLink   } from 'react-icons/fa';

const UseCasesContainer = styled.section`
  padding: 5rem 10%;
  background-color: #ffffff;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #141e30;
  margin-bottom: 3rem;
`;

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const Card = styled(motion.div)`
  perspective: 1000px;
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  /* Remove hover effect */
  &:hover {
    transform: none; /* This prevents the card from rotating */
  }
`;
const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #f5f5f5;
  color: #141e30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  box-sizing: border-box;
`;

const CardBack = styled(CardFace)`
  background: linear-gradient(45deg, #29abe2, #4f00bc);
  color: #ffffff;
  transform: rotateY(180deg);
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  margin: 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  background: #29abe2;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
`;

const useCases = [
  {
    icon: <FaLink />,
    title: "Web3",
    shortDescription: "Empowers users with data control, privacy, and seamless interactions across decentralized platforms.",
    longDescription: "Web3 enables secure, trustless interactions, giving users control over their data and privacy. By removing intermediaries, it opens up opportunities in DeFi, governance, and decentralized ecosystems, fostering autonomy and enhancing participation across platforms."
  },
  {
    icon: <FaVrCardboard />,
    title: "Metaverse",
    shortDescription: "Enables decentralized identities, allowing users to control their virtual presence and assets securely.",
    longDescription: "In the metaverse, decentralized identities provide users with seamless control over their virtual assets and interactions. This ensures privacy, secure transactions, and interoperability between virtual environments, offering more personalized and trusted experiences."
  },
  {
    icon: <FaMoneyBillWave />,
    title: "DeFi",
    shortDescription: "Provides secure, decentralized identities for seamless access to financial services with full data ownership.",
    longDescription: "DeFi platforms benefit from decentralized identities by enabling secure, direct access to financial services without intermediaries. Users retain ownership of their data and assets while participating confidently in decentralized finance, knowing their identities are protected."
  },
  {
    icon: <FaGamepad />,
    title: "Gaming",
    shortDescription: "Securely manage identities and digital assets for enhanced gaming interactions and ownership.",
    longDescription: "Decentralized identities in gaming allow players to control their in-game assets and avatars securely. This fosters better interactions, ownership of virtual items, and cross-game interoperability, giving gamers more control and security in their gaming experiences."
  },
  {
    icon: <FaHeartbeat />,
    title: "Healthcare",
    shortDescription: "Gives patients with control over their medical data, ensuring privacy and secure access across healthcare providers.",
    longDescription: "Decentralized identities in healthcare give patients full ownership of their medical records, allowing secure sharing and access across multiple healthcare providers. This ensures privacy, reduces data breaches, and enables patients to manage their health data with transparency and trust."
  },
  {
    icon: <FaRoad  />,
    title: "Transportation Trafic Data",
    shortDescription: "Ensures secure, decentralized management of personal traffic data, protecting privacy while offering real-time insights.",
    longDescription: "With decentralized identities in transportation, users can securely control and manage their traffic data, ensuring that no unauthorized party can access or track their movements. This guarantees privacy while allowing users and authorities to access real-time data for improved traffic management, reducing congestion and enhancing safety."
    },
    {
    icon: <FaComments />,
    title: "Social Media",
    shortDescription: "Gives users control over their data and identity, ensuring privacy and secure interactions on social platforms.",
    longDescription: "Decentralized identities in social media allow users to manage their personal information, control how it's shared, and ensure their privacy across platforms. This prevents unauthorized data usage, enhances security in interactions, and provides users with full ownership of their social media presence without relying on centralized control."
    },
    {
      icon: <FaBuilding />,
      title: "Real Estate",
      shortDescription: "Enables secure, decentralized management of property transactions and ownership data.",
      longDescription: "Decentralized identities in real estate allow buyers, sellers, and agents to securely verify identities and manage property transactions. This ensures privacy, reduces fraud, and simplifies the transfer of ownership while keeping all records transparent and tamper-proof."
    }
];

const UseCasesSection = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const openModal = (card) => {
    setSelectedCard(card);
  };

  const closeModal = () => {
    setSelectedCard(null);
  };

  return (
    <UseCasesContainer id="use-cases">
      <Title>Transforming Multiple Industries</Title>
      <CardsGrid>
        {useCases.map((card, index) => (
          <Card key={index} onClick={() => openModal(card)}>
            <CardInner>
              <CardFace>
                <IconWrapper>{card.icon}</IconWrapper>
                <CardTitle>{card.title}</CardTitle>
                <CardDescription>{card.shortDescription}</CardDescription>
              </CardFace>
              <CardBack>
                <CardDescription>{card.shortDescription}</CardDescription>
              </CardBack>
            </CardInner>
          </Card>
        ))}
      </CardsGrid>

      {selectedCard && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>{selectedCard.title}</h3>
            <p>{selectedCard.longDescription}</p>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </UseCasesContainer>
  );
};

export default UseCasesSection;
