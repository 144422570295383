// src/components/HowItWorksSection.js

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FiUserCheck, FiSettings, FiShield, FiTrendingUp , FiUserPlus, FiAward  } from 'react-icons/fi';

const HowItWorksContainer = styled.section`
  padding: 5rem 10%;
  background-color: #f9f9f9;
  text-align: center;
  overflow-x: hidden; /* Ensure no horizontal overflow */

`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #141e30;
  margin-bottom: 3rem;
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Step = styled(motion.div)`
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  text-align: left;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column !important;
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  font-size: 4rem;
  color: #29abe2;
  flex-shrink: 0;
  margin: 0 2rem;

  @media (max-width: 768px) {
    margin: 0 0 1rem 0;
  }
`;

const TextContent = styled.div`
  max-width: 500px;
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  color: #141e30;
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
`;

const HowItWorksSection = () => {
  return (
    <HowItWorksContainer id="how-it-works">
      <Title>How Identium Works</Title>
      <StepsWrapper>
        <Step
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <IconWrapper>
            <FiUserCheck />
          </IconWrapper>
          <TextContent>
            <StepTitle>Step 1: Create Your Identity</StepTitle>
            <StepDescription>
              Sign up and establish your unique decentralized digital identity securely on our blockchain network.
            </StepDescription>
          </TextContent>
        </Step>
        <Step
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <IconWrapper>
            <FiUserPlus />
          </IconWrapper>
          <TextContent>
            <StepTitle>Step 2: Level up through incentive rewards</StepTitle>
            <StepDescription>
            Enhance your digital identity and Earn incentive rewards that increase as you contribute more, helping you unlock higher levels of personalization and security.
            </StepDescription>
          </TextContent>
        </Step>

        <Step
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <IconWrapper>
            <FiAward  />
          </IconWrapper>
          <TextContent>
            <StepTitle>Step 3: Get Your Passport</StepTitle>
            <StepDescription>
              Obtain your Identium Passport, a digital credential that provides seamless access to decentralized platforms while ensuring your privacy and security.
            </StepDescription>
          </TextContent>
        </Step>

        <Step
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <IconWrapper>
            <FiShield />
          </IconWrapper>
          <TextContent>
            <StepTitle>Step 4: Secure and Control Data</StepTitle>
            <StepDescription>
              Manage your data with full transparency and control, ensuring privacy and security across all platforms.
            </StepDescription>
          </TextContent>
        </Step>
        <Step
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <IconWrapper>
            <FiTrendingUp  />
          </IconWrapper>
          <TextContent>
            <StepTitle>Step 5: Generate Revenue </StepTitle>
            <StepDescription>
              Opt-in to our data marketplace and generate revenue by sharing your verified data with trusted entities.
            </StepDescription>
          </TextContent>
        </Step>
      </StepsWrapper>
    </HowItWorksContainer>
  );
};

export default HowItWorksSection;
