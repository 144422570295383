// src/components/FeaturesSection.js

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaGift, FaLock, FaCoins, FaGlobe, FaUserCheck,FaFingerprint, FaPassport ,FaUserAstronaut} from 'react-icons/fa';

const FeaturesContainer = styled.section`
  padding: 5rem 10%;
  background-color: #ffffff;
  text-align: center;
`;

const FeaturesTitle = styled.h2`
  font-size: 2.5rem;
  color: #141e30;
  margin-bottom: 3rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled(motion.div)`
  background: #f5f5f5;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    background: #ffffff;
    box-shadow: 0 15px 25px rgba(0,0,0,0.1);
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #4f00bc;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #141e30;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
`;

const FeaturesSection = () => {
  return (
    <FeaturesContainer id="features">
      <FeaturesTitle>Key Features of Identium</FeaturesTitle>
      <FeaturesGrid>
      
        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaUserCheck />
          </IconWrapper>
          <FeatureTitle>Multi-Modal Identity Capturing</FeatureTitle>
          <FeatureDescription>
          Extract and analyze biometric and behavioral features using AI-powered technology to uniquely identify individuals, all without relying on special devices or hardware.          </FeatureDescription>
        </FeatureCard>

        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaFingerprint />
          </IconWrapper>
          <FeatureTitle>ZKP Uniqueness Technique</FeatureTitle>
          <FeatureDescription>
          Guarantee a high level of user uniqueness with advanced Zero-Knowledge Proof (ZKP) algorithm, allowing identity verification without revealing personal data. </FeatureDescription>
        </FeatureCard>


        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaGift />
          </IconWrapper>
          <FeatureTitle>Incentive Reward for Participating</FeatureTitle>
          <FeatureDescription>
          Earn rewards for actively engaging with platform. The more you participate, the higher your reward tiers. Level up by completing tasks, sharing data under your control, to unlock bigger incentives.
          </FeatureDescription>
        </FeatureCard>

        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaLock />
          </IconWrapper>
          <FeatureTitle>Blockchain Security</FeatureTitle>
          <FeatureDescription>
          Decentralized blockchain infrastructure guarantees data privacy, ownership, and integrity with high security. It provides fast, efficient performance while ensuring users control their data.          </FeatureDescription>
        </FeatureCard>
      
        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaPassport />
          </IconWrapper>
          <FeatureTitle>Issue Identium Passport</FeatureTitle>
          <FeatureDescription>
          Your digital passport enables secure, decentralized identity management, granting access to global platforms without relying on central authorities. It ensures privacy protection while allowing seamless interactions across various digital environments.          </FeatureDescription>
        </FeatureCard>

        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaCoins />
          </IconWrapper>
          <FeatureTitle>Own, Monetize Your Data</FeatureTitle>
          <FeatureDescription>
          Take full control of your personal data, ensuring privacy and ownership in every interaction. Opt-in to share your data securely and start to generate revenue through our innovative data marketplace.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaUserAstronaut />
          </IconWrapper>
          <FeatureTitle>AI-Powered Digital Twin</FeatureTitle>
          <FeatureDescription>
          Enjoy a dynamic digital identity that evolves based on your behaviors, preferences, and habits, offering a more personalized experience. This adaptable identity enhances interactions across platforms while maintaining security and privacy.
          </FeatureDescription>
        </FeatureCard>


        <FeatureCard
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <IconWrapper>
            <FaGlobe />
          </IconWrapper>
          <FeatureTitle>Seamless Interoperability</FeatureTitle>
          <FeatureDescription>
          Ensures your digital identity remains consistent and secure as you interact across various industries and platforms. This enables smooth transitions and trusted engagements without the need for multiple identities.          </FeatureDescription>
        </FeatureCard>
      </FeaturesGrid>
    </FeaturesContainer>
  );
};

export default FeaturesSection;
