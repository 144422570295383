import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HeroContainer = styled.section`
  background: linear-gradient(to right, #141e30, #243b55); /* Dark gradient background */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding: 0 20px;
  overflow-x: hidden; /* Prevent horizontal overflow */
  width: 100%; /* Ensure container doesn't exceed viewport width */
  box-sizing: border-box; /* Include padding in width calculation */
`;

const HeroContent = styled.div`
  max-width: 800px;
  width: 100%; /* Make sure the content doesn't overflow horizontally */
`;

const ProjectName = styled.h2`
  font-size: 5rem;
  margin-bottom: 1rem;
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 3rem; /* Adjust font size for mobile devices */
  }
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 3rem;
  
  @media (max-width: 768px) {
    font-size: 2.5rem; /* Adjust font size for mobile devices */
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem; /* Adjust font size for mobile devices */
  }
`;

const CTAButton = styled(motion.a)`
  display: inline-block;
  padding: 1rem 2.5rem;
  background: #29abe2;
  color: #ffffff;
  border-radius: 50px;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;

  &:hover {
    background: #22a1d3;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust button font size for mobile */
    padding: 0.8rem 2rem; /* Adjust padding for mobile */
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <ProjectName>IDENTIUM</ProjectName>
        <HeroTitle>Redefine Your Identity</HeroTitle>
        <HeroSubtitle>Leading the Future of Digital Identity</HeroSubtitle>
        <CTAButton href="https://app.identium.io" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          Get Started
        </CTAButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
