// src/components/Footer.js

import React from 'react';
import styled from 'styled-components';
import { FaTelegram, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 3rem 10%;
  background-color: #141e30;
  color: #ffffff;
  text-align: center;
`;

const FooterLinks = styled.div`
  margin-bottom: 1rem;

  a {
    color: #29abe2;
    margin: 0 1rem;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialIcons = styled.div`
  margin-top: 1rem;
  a {
    color: #29abe2;
    margin: 0 0.5rem;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #22a1d3;
    }
  }
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <a href="#about-us">About Us</a>
        <a href="#features">Features</a>
        <a href="#contact-us">Contact Us</a>
        <a href="#privacy-policy">Privacy Policy</a>
      </FooterLinks>
      <SocialIcons>
        <a href="https://t.me/identium"><FaTelegram /></a>
        <a href="https://x.com/0xidentium"><FaTwitter /></a>
        <a href="#linkedin"><FaLinkedin /></a>
        <a href="#instagram"><FaInstagram /></a>
      </SocialIcons>
      <FooterText>&copy; 2024 Identium. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
